import React from "react";
import "./styles/Navbar.scoped.scss";
import SvgSelector from "../SvgSelector/SvgSelector";
import NavbarBtn from "./components/navbar-btn/NavbarBtn";
import NavbarCardBottom from "./components/navbar-card-bottom/NavbarCardBottom";
import {modalKeys, RouterPath} from "../../../utils/utils";
import {useDispatch, useSelector} from "react-redux";
import {dispatcherProfile} from "../../../store/reducers/dispatcher/profile";
import {showModal} from "../../../store/reducers/modals";

const Navbar = () => {
  const currentPath = window.location.pathname;
  const profile = useSelector(dispatcherProfile);
  const dispatch = useDispatch();
  function openLogoutModal() {
    dispatch(showModal({ key: modalKeys.logoutModal }));
  }
  function setSvgColor(path) {
    return currentPath === path ? "white" : "#6F767E";
  }
  const navItems = [
    {
      to: RouterPath.urList,
      svg: "client-icon",
      svgColor: setSvgColor(RouterPath.urList),
      text: "Предприятия",
    },
    {
      to: RouterPath.empList,
      svg: "emp-list",
      svgColor: setSvgColor(RouterPath.empList),
      text: "Сотрудники",
    },
    {
      to: RouterPath.apps,
      svg: "applications",
      svgColor: setSvgColor(RouterPath.apps),
      text: "Заявки",
    },
    {
      to: RouterPath.map,
      svg: "map-icon",
      svgColor: setSvgColor(RouterPath.map),
      text: "Карта",
    },
    {
      to: RouterPath.typesOfServices,
      svg: "typesOfServices",
      svgColor: setSvgColor(RouterPath.typesOfServices),
      text: "Услуги",
    },
    {
      to: RouterPath.areas,
      svg: "typesOfServices",
      svgColor: setSvgColor(RouterPath.areas),
      text: "Тарифы",
    },
    {
      to: RouterPath.account,
      svg: "accounts",
      svgColor: setSvgColor(RouterPath.account),
      text: "Финансы",
    },

  ];
  return (
    <div className="navbar">
      <div className="navbar__head head">
        <div className="head__header header">
          <SvgSelector id="logo" className="header__logo" />
          <div className="header__company">Сахаспецтранс</div>
        </div>
        <ul className="head__list list">
          {navItems.map((item, index) => {
            return (
              <NavbarBtn
                className="list__item"
                to={item.to}
                key={index}
                index={index}
              >
                <SvgSelector
                  id={item.svg}
                  svgColor={item.svgColor}
                  className="list__item_icon"
                />
                <span className="list__item_text">{item.text}</span>
              </NavbarBtn>
            );
          })}
        </ul>
      </div>
      <div>
        <div className="exit-btn" onClick={() => openLogoutModal()}>
          <SvgSelector id="exit-btn"/>
          <h3>Выход</h3>
        </div>
        <div className="navbar__bottom bottom">
          <NavbarCardBottom profile={profile} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
