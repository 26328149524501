import React, {
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  createDispatcherOrderAddressAsync,
  editDispatcherOrderAddressAsync,
  editDispatcherOrderByIdAsync,
  getDispatcherOrderByIdAsync,
  getDispatcherOrderHistoryByIdAsync,
  getDispatcherOrderListAsync,
} from "../../../../../store/reducers/dispatcher/order";
import ApplicationProcessModalStoryMode from "./components/modes/ApplicationProcessModalStoryMode";
import ApplicationProcessModalExecutorMode from "./components/modes/ApplicationProcessModalExecutorMode";
import ApplicationProcessModalAppMode from "./components/modes/ApplicationProcessModalAppMode/ApplicationProcessModalAppMode";
import { appListPerPage } from "../../../../../utils/constants";
import "../../styles/ApplicationModal.scoped.scss";
import {
  convertDate,
  convertPrice,
  convertQuantities,
  convertTimes,
} from "../../../../../scripts/commonScripts";
import { setIsClosable } from "../../../../../store/reducers/modals";
import {
  getDispatcherCustomerAddressListAsync,
  getExecutorsListAsync,
} from "../../../../../store/reducers/dispatcher/account";
import { addError } from "../../../../../store/reducers/alerts";

export const AppProcessModalContext = createContext();

const ApplicationProcessModal = ({ closeModal, options }) => {
  const dispatch = useDispatch();
  const { dispatcherOrder, dispatcherOrderHistory } = useSelector(
    (state) => state.dispatcherOrder
  );
  const { servicesList } = useSelector((state) => state.commonCatalog);
  const { organizations } = useSelector((state) => state.dispatcherCatalog);

  const [organizationsData, setOrganizationsData] = useState(organizations);
  const [modalView, setModalView] = useState("app");

  const [dispatcherComment, setDispatcherComment] = useState(
    dispatcherOrder?.dispatcher_comment
  );
  const [dateData, setDateData] = useState(dispatcherOrder?.date);
  const [timeData, setTimeData] = useState(dispatcherOrder?.time);
  const [quantityData, setQuantityData] = useState(dispatcherOrder?.quantity);
  const [priceData, setPriceData] = useState(
    convertPrice(dispatcherOrder?.price, "front")
  );
  const [statusData, setStatusData] = useState(dispatcherOrder?.status);
  const [customer, setCustomer] = useState(dispatcherOrder?.customer);
  const [customerType, setCustomerType] = useState(
    dispatcherOrder?.customer_type
  );
  const [orderAddresses, setOrderAddresses] = useState(
    dispatcherOrder?.order_addresses
  );
  const [service, setService] = useState(dispatcherOrder?.service);
  const [serviceId, setServiceId] = useState(dispatcherOrder?.service?.id);
  const [customerComment, setCustomerComment] = useState(
    dispatcherOrder?.customer_comment
  );
  const [executorComment, setExecutorComment] = useState(
    dispatcherOrder?.executor_comment
  );
  const [orderHistory, setOrderHistory] = useState(
    dispatcherOrder?.order_history
  );
  const [paid, setPaid] = useState(dispatcherOrder?.paid);
  const [organizationId, setOrganizationId] = useState(
    dispatcherOrder?.executor?.organization?.id
  );
  const [executorId, setExecutorId] = useState(dispatcherOrder?.executor?.id);
  const [executor, setExecutor] = useState(dispatcherOrder?.executor);

  const [quantitiesList, setQuantitiesList] = useState(() =>
    convertQuantities(service?.quantities, service?.measure)
  );
  const [currentQuantity, setCurrentQuantity] = useState(
    () => convertQuantities([quantityData], service?.measure)[0]
  );
  const [currentDate, setCurrentDate] = useState(() => convertDate(dateData));
  const [timesList, setTimesList] = useState(() =>
    convertTimes(service?.times)
  );
  const [currentTime, setCurrentTime] = useState(
    () => convertTimes([timeData])[0]
  );
  const [address, setAddress] = useState(
    !!orderAddresses?.length ? orderAddresses[0]?.address?.id : 0
  );
  const [addressRoute1, setAddressRoute1] = useState(
    !!orderAddresses?.length ? orderAddresses[0]?.address?.id : 0
  );
  const [addressRoute2, setAddressRoute2] = useState(
    !!orderAddresses?.length ? orderAddresses[1]?.address?.id : 0
  );
  const [customerId, setCustomerId] = useState(dispatcherOrder?.customer?.id);
  const [district, setDistrict] = useState(dispatcherOrder?.district);
  const [districtId, setDistrictId] = useState(dispatcherOrder?.district?.id);
  const [idIP] = useState(localStorage.getItem("ip-id"));
  const [idLegal] = useState(localStorage.getItem("legal-id"));
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);

  const personStatus = [
    { id: idIP, name: "ИП" },
    { id: idLegal, name: "Юр.лицо" },
  ];

  function setOrderHistoryId(id) {
    dispatch(getDispatcherOrderHistoryByIdAsync(id));
  }
  function editDispatcherOrderById({ status }) {
    const errors = [];
    if (!!dateData?.length && statusData !== "wait_payment") {
      const currYear = new Date().getFullYear().toString();
      let currMonth = new Date().getMonth() + 1;
      let currDate = new Date().getDate().toString();
      const yearFromDate = dateData.split(".")[2];
      const monthFromDate = dateData.split(".")[1];
      const dayFromDate = dateData.split(".")[0];

      if (currMonth?.toString().length === 1) {
        currMonth = "0" + currMonth.toString();
      } else currMonth.toString();

      if (currDate?.toString().length === 1) {
        currDate = "0" + currDate.toString();
      } else currDate.toString();
      if((currMonth - monthFromDate !== 0) && (currMonth - monthFromDate !== -1)) errors.push("Выберите правильный месяц");
      if ((currMonth - monthFromDate === -1) && dayFromDate > currDate) errors.push("Выберите правильный месяц");
      // if (currMonth !== monthFromDate) errors.push("Выберите текущий месяц");
      if (yearFromDate > currYear) errors.push("Выбранный год больше текущего");
      if (yearFromDate < currYear) errors.push("Выбранный год меньше текущего");
      if (
        currDate > dayFromDate &&
        currMonth === monthFromDate &&
        currYear === yearFromDate
      ) {
        errors.push("Выбрана некорректная дата");
      }
    }
    if (!!errors.length) for (const error of errors) dispatch(addError(error));
    if (!errors.length) {
      const data = {
        /*service: dispatcherOrder?.service.id,*/
        /*executor: executorId?.id,*/
      };
      if (selectedDiscounts?.length) data.discounts = selectedDiscounts;
      if (executorId && (executorId !== dispatcherOrder?.executor?.id)) data.executor = executorId?.id
      if (districtId && (districtId !== dispatcherOrder?.district?.id)) data.district = districtId;
      if (status === "finished" || status === "approved" || status === "canceled") data.status = status;
      if (dateData && (dateData !== dispatcherOrder?.date)) data.date = dateData;
      if (timeData && (timeData !== dispatcherOrder?.time)) data.time = timeData;
      if (priceData && (convertPrice(priceData, "back") !== dispatcherOrder?.price)) data.price = convertPrice(priceData, "back");
      if (quantityData && (quantityData !== dispatcherOrder?.quantity)) data.quantity = quantityData;
      if (dispatcherComment && (dispatcherComment !== dispatcherOrder?.dispatcher_comment)) data.dispatcher_comment = dispatcherComment;

      if(Object.values(data).length > 0) {
          dispatch(editDispatcherOrderByIdAsync(dispatcherOrder?.id, data))
              .then(() => {
                  setTimeout(() => {
                      dispatch(
                          getDispatcherOrderListAsync({
                              page: 1, page_size: appListPerPage,
                            })
                      );}, 0);
              });
        }
        if (!!dispatcherOrder?.id) {
            setTimeout(() => {
                if (!!dispatcherOrder?.order_addresses?.length) {
                    if (
                        service?.destination === "address" &&
                        dispatcherOrder?.order_addresses[0]?.address?.id !== address
                    ) {
                        dispatch(
                            editDispatcherOrderAddressAsync(dispatcherOrder.order_addresses[0].id, {
                                address,
                                type: "destination",
                            })
                        );
                    } else {
                        if (
                            addressRoute1 !==
                            dispatcherOrder?.order_addresses[0]?.address?.id
                        ) {
                            dispatch(
                                editDispatcherOrderAddressAsync(addressRoute1, {
                                    address: addressRoute1,
                                    type: "start_route",
                                })
                            );
                        }
                        if (
                            addressRoute2 !==
                            dispatcherOrder?.order_addresses[1]?.address?.id
                        ) {
                            dispatch(
                                editDispatcherOrderAddressAsync(addressRoute2, {
                                    address: addressRoute2,
                                    type: "end_route",
                                })
                            );
                        }
                    }
                }
                if (!dispatcherOrder?.order_addresses?.length) {
                    if (service?.destination === "address") {
                        dispatch(
                            createDispatcherOrderAddressAsync({
                                order: dispatcherOrder?.id,
                                address,
                                type: "destination",
                            })
                        );
                    } else {
                        dispatch(
                            createDispatcherOrderAddressAsync({
                                order: dispatcherOrder?.id,
                                address: addressRoute1,
                                type: "start_route",
                            })
                        );
                        dispatch(
                            createDispatcherOrderAddressAsync({
                                order: dispatcherOrder?.id,
                                address: addressRoute2,
                                type: "end_route",
                            })
                        );
                    }
                    dispatch(
                        getDispatcherOrderListAsync({
                            page: 1,
                            page_size: appListPerPage,
                        })
                    );
                }
                dispatch(getDispatcherOrderByIdAsync(options?.option?.id));
            }, 500);
        }
    }
  }

  function setStatusDataFunc(e) {
    setStatusData(e);
    setTimeout(() => {
      dispatch(() => editDispatcherOrderById({ status: e }));
    }, 300);
  }

  function setCustomerIdData(id) {
    setCustomerId(id);
  }

  useLayoutEffect(() => {
    dispatch(setIsClosable(false));
    dispatch(getDispatcherOrderByIdAsync(options?.option?.id));
    dispatch(
      getExecutorsListAsync({
        organization: organizationId,
        page: 1,
        page_size: 150000,
      })
    );
    dispatch(
      getDispatcherCustomerAddressListAsync({
        content_type: customerType,
        object_id: customerId,
      })
    );
  }, []);

  useLayoutEffect(() => {
    setPriceData(convertPrice(dispatcherOrder?.price, "front"));
    setDistrictId(dispatcherOrder?.district?.id);
    setDistrict(dispatcherOrder?.district);
    setDateData(dispatcherOrder?.date);
    setTimeData(dispatcherOrder?.time);
    setService(dispatcherOrder?.service);
    setServiceId(dispatcherOrder?.service?.id);
    setStatusData(dispatcherOrder?.status);
    setCustomer(dispatcherOrder?.customer);
    setCustomerId(dispatcherOrder?.customer?.id);
    setCustomerType(dispatcherOrder?.customer_type);
    setOrderAddresses(dispatcherOrder?.order_addresses);
    setCustomerComment(dispatcherOrder?.customer_comment);
    setExecutorComment(dispatcherOrder?.executor_comment);
    setDispatcherComment(dispatcherOrder?.dispatcher_comment);
    setExecutor(dispatcherOrder?.executor);
    setOrderHistory(dispatcherOrder?.order_history);
    setPaid(dispatcherOrder?.paid);
    setQuantityData(dispatcherOrder?.quantity);

    if (!!orderAddresses?.length) {
      setAddress(orderAddresses[0]?.address?.id);
      setAddressRoute1(orderAddresses[0]?.address?.id);
      setAddressRoute2(orderAddresses[1]?.address?.id);
    }
  }, [
    dispatcherOrder,
    dispatcherOrder?.service,
    dispatcherOrder?.order_history,
    dispatcherOrder?.customer_comment,
    dispatcherOrder?.executor_comment,
    dispatcherOrder?.dispatcher_comment,
    options?.option?.id,
    orderAddresses,
  ]);

  useLayoutEffect(() => {
    setCurrentDate(() => convertDate(dateData));
    setTimesList(() => convertTimes(dispatcherOrder?.service?.times));
    setCurrentTime(convertTimes([timeData])[0]);
    setQuantitiesList(
      convertQuantities(
        dispatcherOrder?.service?.quantities,
        dispatcherOrder?.service?.measure
      )
    );
    setCurrentQuantity(
      convertQuantities([quantityData], dispatcherOrder?.service?.measure)[0]
    );
  }, [dateData, quantityData, dispatcherOrder?.service, timeData]);

  useEffect(() => {
    setTimeout(() => {
      setOrganizationsData(organizations);
    }, 0);
  }, [organizations]);

  return (
    <AppProcessModalContext.Provider
      value={{
        setSelectedDiscounts,
        selectedDiscounts,
        district,
        setDistrict,
        setDistrictId,
        personStatus,
        customer,
        customer_type: customerType,
        servicesList,
        service,
        setService,
        setQuantityData,
        organizationsData,
        setModalView,
        setDispatcherComment,
        setDateData,
        setTimeData,
        setOrganizationId,
        dispatcherOrder,
        priceData,
        setPriceData,
        paid,
        statusData,
        setStatusData: setStatusDataFunc,
        dispatcherComment,
        customer_comment: customerComment,
        executor_comment: executorComment,
        closeModal,
        order_history: orderHistory,
        setOrderHistoryId,
        organizationId,
        quantitiesList,
        currentQuantity,
        currentDate,
        timesList,
        currentTime,
        editDispatcherOrderById,
        setAddress,
        address,
        setAddressRoute1,
        addressRoute1,
        setAddressRoute2,
        addressRoute2,
        customerId,
        customerType,
        setCustomerIdData,
        quantityData,
        executor,
        executorId,
        setExecutorId,
        serviceId,
        isShowDDIcon: true,
        dispatcherOrderHistory,
      }}
    >
      {modalView === "app" && <ApplicationProcessModalAppMode />}
      {modalView === "executor" && (
        <ApplicationProcessModalExecutorMode mode={"old"} />
      )}
      {modalView === "story" && <ApplicationProcessModalStoryMode />}
    </AppProcessModalContext.Provider>
  );
};

ApplicationProcessModal.defaultProps = {
  closeModal: () => {},
  options: {},
};

ApplicationProcessModal.propTypes = {
  closeModal: PropTypes.func,
  options: PropTypes.object,
};

export default ApplicationProcessModal;
