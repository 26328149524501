import React, { useEffect, useState } from "react";
import "../styles/EmpAddModal.scss";
import AppInput from "../../../layout/AppInput/AppInput";
import AppButton from "../../../layout/AppButton/AppButton";
import EventEmitter from "../../../../utils/EventEmitter";
import AppSelect from "../../../layout/AppSelect/AppSelect";
import AppPhoneInput from "../../../layout/AppPhoneInput/AppPhoneInput";
import { addError } from "../../../../store/reducers/alerts";
import { useDispatch, useSelector } from "react-redux";
import AppTextarea from "../../../layout/AppTextarea/AppTextarea";
import AppUploader from "../../../layout/AppUploader/AppUploader";
import AppCheckbox from "../../../layout/AppCheckbox/AppCheckbox";
import {
  getDistrictListAsync,
  getServicesListAsync,
} from "../../../../store/reducers/common/catalog";
const ExecutorAddModal = ({ options, closeModal }) => {
  const executorTypes = [
    { id: "water", name: "Водовоз" },
    { id: "waste", name: "Ассенизатор" },
    { id: "excavator", name: "Экскаватор" },
    { id: "boer", name: "Буровая" },
    { id: "bulldozer", name: "Бульдозер" },
    { id: "loader", name: "Погрузчик" },
    { id: "tipper", name: "Самосвал" },
    { id: "tractor", name: "Тягач" },
    { id: "oil", name: "Нефтевоз" },
    { id: "crane", name: "Кран" },
  ];

  const dispatch = useDispatch();
  const { servicesList, districtList } = useSelector(
    (store) => store.commonCatalog
  );
  const { dispatcherProfile } = useSelector(
    (store) => store?.dispatcherProfile
  );
  const { organizations } = useSelector((state) => state.dispatcherCatalog);
  const [modalTitle] = useState(setModalTitleData());
  const [actionButtonText] = useState(setActionButtonText());
  const [organizationsData, setOrganizationsData] = useState(organizations);
  const [filePreviewData, setFilePreviewData] = useState("");
  const [servicesListData, setServicesListData] = useState(servicesList);
  const [districtListData, setDistrictListData] = useState(districtList);

  const [emp, setEmp] = useState({
    id: "",
    fio: "",
    services: [],
    phone: "",
    email: "",
    photo: "",
    organization: "",
    address: "",
    type: "",
    district: dispatcherProfile?.district?.id,
    meta: "existed",
  });

  const [isFioVal, setFioVal] = useState(false);
  const [isPhoneVal, setPhoneVal] = useState(false);
  const [isEmailVal, setEmailVal] = useState(false);
  const [isAddressVal, setAddressVal] = useState(false);
  // const [isDistrictVal, setDistrictVal] = useState(false);
  const [executorServicesArr] = useState(options?.services);

  function setActionButtonText() {
    if (options?.mode === "edit") return "Редактировать исполнителя";
    else return "Добавить исполнителя";
  }

  function setModalTitleData() {
    if (options?.mode === "edit") return "Редактировать исполнителя";
    else return "Добавить исполнителя";
  }

  function setPhoto(eventData) {
    setEmp(eventData);
  }

  function setFilePreview(eventData) {
    setFilePreviewData(eventData);
  }

  function validate(func, data) {
    const errors = [];
    if (!data?.fio?.length) errors.push("Не указано имя исполнителя");
    if (!data?.email?.length) errors.push("Не указан email исполнителя");
    if (!data?.phone?.length) errors.push("Не указан телефон исполнителя");
    // if (!data?.photo) errors.push("Не добавлено фото исполнителя");
    if (!data?.address?.length) errors.push("Не указан адрес исполнителя");
    // if (!data?.district?.toString().length) errors.push("Не указан район");
    if (!errors?.length) {
      func();
    }
    if (errors?.length) {
      for (const error of errors) dispatch(addError(error));
    }
  }

  function editExecutor() {
    setEmp({ ...emp, services: executorServices() });
    EventEmitter.emit("editExecutor", {
      ...emp,
      filePreview: filePreviewData,
      services: executorServices(),
    });
  }

  function executorServices() {
    if (!!servicesListData?.length) {
      const arrObj = servicesListData.filter((item) => item.selected);
      const arrIds = [];
      for (const item of arrObj) arrIds.push(item.id);
      return arrIds;
    }
  }

  function addNewExecutor() {
    setEmp({ ...emp, services: executorServices() });
    EventEmitter.emit("addNewExecutor", {
      ...emp,
      filePreview: filePreviewData,
      services: executorServices(),
    });
  }

  function modalAction() {
    if (options?.mode === "edit") validate(editExecutor, emp);
    else validate(addNewExecutor, emp);
  }
  function setSelectedSpec(idx) {
    let prevArr = servicesListData.map((item) => Object.assign({}, item));
    prevArr[idx].selected = !prevArr[idx].selected;
    setServicesListData(prevArr);
  }

  useEffect(() => {
    const addOrganisation = (eventData) => {
      setEmp({ ...emp, company: eventData.name });
    };
    const addOrganisationListener = EventEmitter.addListener(
      "addOrganisation",
      addOrganisation
    );
    return () => {
      addOrganisationListener.remove();
    };
  }, [emp]);

  function searchOrg(id) {
    if (id) return organizationsData.find((el) => el.id === id);
  }

  function searchDis(id) {
    if (id) return districtListData.find((el) => el.id === id);
  }
  const checkValidFio = (isValid) => {
    setFioVal(isValid);
  };

  const checkValidPhone = (isValid) => {
    setPhoneVal(isValid);
  };

  const checkValidEmail = (isValid) => {
    setEmailVal(isValid);
  };

  const checkValidAddress = (isValid) => {
    setAddressVal(isValid);
  };

  const isDisabledActionButton = () => {
    return (
      !isFioVal ||
      !isPhoneVal ||
      !isEmailVal ||
      !isAddressVal ||
      !emp?.organization ||
      !emp?.type
    );
  };

  useEffect(() => {
    dispatch(getServicesListAsync({ page: 1, page_size: 150000 }));
    dispatch(getDistrictListAsync({ page: 1, page_size: 150000 }));
  }, []);

  useEffect(() => {
    setServicesListData(servicesList);
  }, [servicesList]);

  useEffect(() => {
    setDistrictListData(districtList);
  }, [districtList]);

  useEffect(() => {
    setTimeout(() => {
      setOrganizationsData(organizations);
    }, 0);
  }, [organizations]);

  useEffect(() => {
    setTimeout(() => {
      setEmp({
        id: emp?.id,
        idx: emp?.idx,
        fio: emp?.fio,
        services: emp?.services,
        phone: emp?.phone,
        email: emp?.email,
        photo: emp?.photo,
        address: emp?.address,
        organization: emp?.organization,
        meta: emp?.meta,
        type: emp?.type,
        district: emp?.district,
      });
    }, 0);
  }, [
    emp.id,
    emp.fio,
    emp.services,
    emp.phone,
    emp.email,
    emp.photo,
    emp.address,
    emp?.organization,
    emp.meta,
    emp?.idx,
    emp?.type,
    emp?.district,
  ]);

  return (
    <>
      <h1 className="add-emp-modal-name">{modalTitle}</h1>
      <div className="add-emp-wrapper">
        <div className="left-add-side">
          <AppUploader
            data={emp}
            setterFunc={setPhoto}
            setFilePreviewFunc={setFilePreview}
          />
          <div className="input-area">
            <AppSelect
              label="Тип исполнителя"
              optionsList={executorTypes}
              onInput={(e) => setEmp({ ...emp, type: e })}
              mode="grey"
              isReq={true}
            />
            <AppInput
              className="modal-input"
              label="ФИО"
              value={emp?.fio}
              placeholder="Указать ФИО"
              validations={{ isEmpty: true }}
              checkValid={checkValidFio}
              onEnterActionFunc={() => modalAction()}
              onInput={(e) => setEmp({ ...emp, fio: e })}
              isReq={true}
            />
            <AppPhoneInput
              className="modal-input"
              label="Телефон"
              value={emp?.phone}
              validations={{ isEmpty: true, minLength: 18 }}
              placeholder="+7 (___) ___-__-__"
              checkValid={checkValidPhone}
              onEnterActionFunc={() => modalAction()}
              onInput={(e) => setEmp({ ...emp, phone: e })}
              isReq={true}
            />
            <AppInput
              className="modal-input"
              label="E-mail"
              value={emp?.email}
              placeholder="Указать e-mail"
              validations={{ isEmpty: true, isEmail: true }}
              checkValid={checkValidEmail}
              onEnterActionFunc={() => modalAction()}
              onInput={(e) => setEmp({ ...emp, email: e })}
              isReq={true}
            />
            <AppTextarea
              label="Адрес проживания"
              value={emp?.address}
              placeholder="Указать адрес"
              validations={{ isEmpty: true }}
              checkValid={checkValidAddress}
              onEnterActionFunc={() => modalAction()}
              onInput={(e) => setEmp({ ...emp, address: e })}
              isReq={true}
            />
            <AppSelect
              className="modal-input"
              label="Сотрудник принадлежит следующей организации"
              onInput={(e) => setEmp({ ...emp, organization: e })}
              validations={{ isEmpty: true }}
              selected={searchOrg(emp?.organization)}
              optionsList={organizationsData}
              mode="grey"
              isReq={true}
            />
            {dispatcherProfile?.is_admin && (
              <AppSelect
                label="Район работы"
                optionsList={districtListData}
                validations={{ isEmpty: true }}
                selected={searchDis(emp?.district)}
                onInput={(e) => {
                  setEmp({ ...emp, district: e });
                }}
                mode="grey"
                isReq={true}
              />
            )}
          </div>
        </div>
        <div className="right-add-side">
          <div className="spec-wrapper">
            {executorServicesArr && (
              <div className="spec-variants">
                <h4 className="area-name">Специализации исполнителя<div className="req">*</div></h4>
                {!!servicesListData?.length &&
                  servicesListData.map((option, idx) => {
                    return (
                      <div className="spec-area" key={idx}>
                        <div className="spec-item">
                          <AppCheckbox
                            mode="blue"
                            checked={option?.selected}
                            onChange={() => setSelectedSpec(idx)}
                          />
                          <h3 className="serv-item-name">{option?.name}</h3>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bottom-add-area">
        <AppButton isDisabled={isDisabledActionButton()} onClick={modalAction}>
          {actionButtonText}
        </AppButton>
      </div>
    </>
  );
};

export default ExecutorAddModal;
